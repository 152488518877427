/**
 * @service         \ https://contacts-service.rrdevelopment.co.uk
 * @middleware      \ https://appURL/api/contact-service/clients
 * @description     \ Contacts service client express routes
 * @baseURL         \ https://contacts-service.rrdevelopment.co.uk/api/clients
 */
import config from '../config';

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ GET
 * @param  \ int
 * @return \ json
 */


// Chat Controller

const chatIndex = async () => {
  const options = {
    base: "https://notification-micro-middleware",
    params: false,
    payload: false,
    endpoint: "/api/chat/log",
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};



const chatIndexByReference = async (id) => {
  const options = {
    base: "https://notification-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/chat/indexByTicket?reference_id=${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const chatIndexByTicketArray = async (obj) => {
  const options = {
    base: "https://notification-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/unity/support/chat/logs/indexByTicketArray`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const chatShow = async (id) => {
  const options = {
    base: "https://notification-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/chat/log/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const chatStore = async (obj) => {
  const options = {
    base: "https://notification-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/chat/log`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const chatUpdate = async (id, obj) => {
  const options = {
    base: "https://notification-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/chat/log/${id}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

//Chat message controller

const messageShow = async (id) => {
  const options = {
    base: "https://notification-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/chat/log/message/${id}`,
    method: "GET"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


const messageStore = async (obj) => {
  const options = {
    base: "https://notification-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/chat/log/message`,
    method: "POST"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const messageUpdate = async (id, obj) => {
  const options = {
    base: "https://notification-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/chat/log/message/${id}`,
    method: "PUT"
  }

  const data = await fetch(
    `${config.appURL}/api/proxy`,
    {
      credentials: "include",
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};


export {
  chatIndex,
  chatStore,
  chatUpdate,
  chatShow,
  chatIndexByReference,
  chatIndexByTicketArray,
  messageShow,
  messageStore,
  messageUpdate
};
