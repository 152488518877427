import React from "react";

export default function ChatMessageItemCustomer({ data }) {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
            <div style={{ flex: 1 }}>
                <img src={`https://eu.ui-avatars.com/api/?name=${data.username}&rounded=true&size=60`} />
            </div>

            <div style={{ flex: 4 }}>
                <div className="customer-box">{data.body}</div>
            </div>
        </div>
    )
}