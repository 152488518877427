import React, { useEffect, useState, useRef } from "react";
import "./root.css";
import Index from "./index";
export default function Root(props) {

  const Pusher = require('pusher-js');

  const [pusher, setPusher] = useState(new Pusher('c5350c80e013ebed7175', {
    cluster: 'eu'
  }));

  const [channel, setChannel] = useState()

  useEffect(() => {

    setChannel(pusher.subscribe(props.auth.tenant_uuid))

  }, [])

  if (pusher && channel) {
    return (
      <Index pusher={pusher} channel={channel} name={props.auth.name} />
    );
  } else {
    return <p>Loading</p>
  }
}
