import React from "react";
import { chatShow } from "./api/chat";

export default function ChatIndexCard({ data, active, setActive, setChatMessages, openTickets }) {
    const activeStyle =
        active &&
        {
            borderLeftWidth: 3, borderLeftStyle: "solid", borderLeftColor: 'white'
        }

    const openTicketObj = openTickets.find(e => e.id === data.reference_id);

    const handleClickEvent = async (data) => {
        setActive(data);
        const res = await chatShow(data.id)
        setChatMessages(res.data.messages)
    }

    return (
        <div onClick={() => handleClickEvent(data)} style={{ display: 'flex', borderBottomStyle: "solid", borderBottomWidth: 1, borderBottomColor: 'black', flexDirection: 'row', padding: 10, ...activeStyle }}>
            <div style={{ flex: 1, paddingLeft: 10 }}>
                <img src={`https://eu.ui-avatars.com/api/?name=${openTicketObj.clientName}&rounded=true&background=fff&size=50`} />
            </div>
            <div style={{ flex: 3 }}>
                {/* <p className="time">{data.created_at}</p> */}
                <p style={{ marginBottom: 1, fontSize: 16, color: 'white' }}>{data.subject}</p>
                <p style={{ marginBottom: 1, fontSize: 12, color: 'white' }}><i>Ref: {data.reference_id}</i></p>
                <hr style={{ margin: 0.5, height: 0.5, 'background-color': 'white', boarder: 'none' }} />
                <p style={{ margin: 0, color: 'white', fontSize: 14 }}>
                    {data.messages.length > 0 ?
                        data.messages[data.messages.length - 1].is_customer ? "Customer Awaiting Response" : "Awaiting Customer Reply"
                        :
                        <p>No Messages</p>
                    }
                </p>
            </div>
        </div>
    )
}